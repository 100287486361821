<template>
  <base-section id="news">
    <base-section-heading title="Educación Continua">
      Consulta las nuevas actividades academicas que tenemos para nuestros miembros
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="4"
        >
          <news-card
            v-bind="news"
            :src="require(`@/assets/article-${i + 1}.jpg`)"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      NewsCard: () => import('@/components/news/Card'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-text',
          date: 'Jul 24, 2021',
          category: 'Bioquímica',
          comments: 13,
          title: 'Curso de Bioquímica',
          text: 'Impartido. por el Dr. Luis Pérez Pérez',
        },
        {
          icon: 'mdi-image',
          date: 'Ene 12, 2022',
          category: 'Hematología',
          comments: 5,
          title: 'Seminario de Hematología',
          text: 'Impartido. por el Dr. Luis Pérez Pérez',
        },
        {
          icon: 'mdi-play',
          date: 'Mar 19, 2022',
          category: 'Quimíca de Sangre',
          comments: 8,
          title: 'Seminario de Quimíca de Sangre',
          text: 'Impartido. por el Dr. Luis Pérez Pérez',
        },




      ],
    }),
  }
</script>
